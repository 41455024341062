<template>
  <b-row class="examination-score-card">
    <b-col sm="6" md="6" lg="2">
      <img src="../../../assets/images/index_pic3.png" alt="" />
    </b-col>
    <b-col sm="6" md="6" lg="2" class="hexagon-triangle">
      <img src="../../../assets/images/hexagon-triangle.png" alt="" />
      <div class="hexagon-triangle-content">
        <b-row>
          <b-col>
            <span class="iconfont icon-defen2">{{ result.testScore }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span>体检得分</span>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col sm="12" md="12" lg="8">
      <b-row class="big-end-form">
        <span>{{masteryDegree()}}</span>
      </b-row>
      <b-row
        v-for="(difficultys, index) in result.sectionDifficultys"
        :key="index"
        class="big-end-form-bottom"
        :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
      >
        <b-col class="big-end-form-left">
          <span>{{ getQuestionType(difficultys.sectionType) }}</span>
        </b-col>
        <b-col class="big-end-form-right">
          <span>{{ difficultys.difficulty }}</span>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    result: {
      default: function () {
        return {};
      },
    },
  },
  methods: {
    masteryDegree() {
      if (this.$route.query.stageId == 1) {
        return "各种题型定级的起始冲刺难度如下：";
      }
      if (this.$route.query.stageId == 3) {
        return "各种题型的掌握难度如下：";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.examination-score-card {
  padding-top: 35px;
}
.big-end-form {
  border: 1px solid #e5e5e5;
  width: 90%;
  margin: 0px auto;
  background-color: #2cb774;
  color: #fff;
  text-align: left;
  padding: 15px;
}
.big-end-form-bottom {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  width: 90%;
  margin: 0 auto;
}
.big-end-form-bottom :nth-last-child(0) {
  border-bottom: 1px solid #e5e5e5;
}
.big-end-form-left {
  height: 100%;
  padding: 15px;
}
.big-end-form-right {
  height: 100%;
  padding: 15px;
}
.hexagon-triangle-content {
  position: absolute;
  top: 33%;
  left: 49%;
  .icon-defen2 {
    font-size: 1.5rem;
  }
}
.off {
  background: #e1f9ed;
}
.hexagon-triangle {
  padding: 60px 0 0 30px;
  position: relative;
  color: $text-white;
}
</style>